import React, { useEffect, useState } from 'react';
import { Modal } from '../../../components';
import { getUserDetails } from '../../../util/dataExtractor';
import { useDispatch } from 'react-redux';
import { pathByRouteName } from '../../../util/routes';
import OfferForm from '../OfferForm/OfferForm';
import css from './OfferPanel.module.css';
import {
  getTransactionTypeData, hasDefaultPaymentMethod, setOrderPageInitialValues
} from '../../CheckoutPage/CheckoutPageTransactionHelpers.js';
import { types as sdkTypes } from '../../../util/sdkLoader';
import StripeOfferForm from './StripeOfferForm/StripeOfferForm';
import { DOTS_PROCESS_NAME, getProcess, isBookingProcessAlias } from '../../../transactions/transaction';

import { clearData } from '../../CheckoutPage/CheckoutPageSessionHelpers';

import { transitions } from '../../../transactions/transactionDotsProcessPurchase';

const { LatLng ,UUID} = sdkTypes;

const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];
// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
      ? PAY_AND_SAVE_FOR_LATER_USE
      : ONETIME_PAYMENT;
};
const STORAGE_KEY = 'CheckoutPage';

const onSubmitCallback = () => {
  clearData(STORAGE_KEY);
};


const getOrderParams = (shippingDetails, config, extraOrderParams = {}, values) => {
  const quantity = 1;
  const quantityMaybe = quantity ? { quantity } : {};
  const deliveryMethod = "shipping";
  const deliveryMethodMaybe = deliveryMethod ? { deliveryMethod } : {};
  const { orderNumber, selectedRates, cartData, defaultRates, listing, stripeCustomerId, stripePaymentMethodId, currentUser } = extraOrderParams || {};


  const { listingType, unitType } = listing?.attributes?.publicData || {};

  const totalPaid = parseFloat(values.formValues.offerPrice) + parseFloat(selectedRates?.amount ? selectedRates?.amount : defaultRates?.amount);
  const protectedDataMaybe = {
    protectedData: {
      ...getTransactionTypeData(listingType, unitType, config),
      ...deliveryMethodMaybe,
      ...shippingDetails,
      orderNumber,
      totalPaid,
      shippingRateDetails: selectedRates?.object_id ? {
        shippingPrice: parseFloat(selectedRates?.amount),
        shippingId: selectedRates?.object_id,
        duration: selectedRates?.duration_terms,
        serviceName: selectedRates?.servicelevel.name,
      } : {
        shippingPrice: parseFloat(defaultRates?.amount),
        shippingId: defaultRates?.object_id,
        duration: defaultRates?.duration_terms,
        serviceName: defaultRates?.servicelevel.name,
      },
      cartData,
      newTransactionFlow: true,
      offerPrice: values.formValues.offerPrice,
      stripe: {
        stripePaymentMethodId,
        stripeCustomerId
      }
    },
  };

  // These are the order parameters for the first payment-related transition
  // which is either initiate-transition or initiate-transition-after-enquiry
  const orderParams = {
    listingId: cartData && cartData.length > 0 ? new UUID(cartData[0].listingId) : listing?.id,
    ...deliveryMethodMaybe,
    ...quantityMaybe,
    ...protectedDataMaybe,
    totalPaid: +parseFloat(totalPaid * 100).toFixed(2),
    offerPrice: values.formValues.offerPrice,
    shippingPrice: selectedRates && selectedRates?.amount ? parseFloat(selectedRates?.amount) : parseFloat(defaultRates?.amount),
    stripeCustomerId,
    stripePaymentMethodId,
    transitionName: transitions.MAKE_AN_OFFER_BY_CUSTOMER,
    currentUser,
    currentListing: listing,
    config
  };
  return orderParams;
};

export default function OfferPanel(props) {

  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [selectedRates, setSelectedRates] = useState({});
  const [isUpdateAddress, setIsUpdateAddress] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [savePaymentMethod, setSavePaymentMethod] = useState(false);
  const [isPaymentMethodChange, setIsPaymentMethodChange] = useState(false);
  const [editAddress, setEditAddress] = useState(false);

  const dispatch = useDispatch();

  const {
    currentListing,
    currentUser,
    routeConfiguration,
    isMakeAnOfferModal,
    handleOfferModal,
    history,
    onSubmitInquiry,
    authorAvailable,
    config,
    combinedListings,
    ind,
    selectedListingId,
    isCartPage,
    stripeCustomerFetched,
    orderNumber,
    orderNumberError,
    defaultRates,
    paymentIntent,
    onRetrievePaymentIntent,
    onConfirmTransactionExtraAttributes,
    onInitiateOffer,
    selectIndex,
    ...rest
  } = props;


  const { paymentMethodId } =
    (!!currentUser?.id && currentUser.attributes.profile.privateData) || {};

  const userName = currentUser?.attributes?.profile
    ? `${currentUser.attributes.profile.displayName}`
    : null;
  const { phoneNumber, location } =
    (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};


  const { apartment, city, postalCode, state, country, address, poBoxNumber } = location || {};

  const initialValues = {
    apartment,
    city,
    postalCode,
    state,
    phoneNumber,
    country,
    address,
    poBoxNumber,
  };

  if (location && location.origin && location.origin.lat && location.address) {
    const { address, origin } = location;
    const { lat, lng } = origin;
    const geolocation = new LatLng(lat, lng);
    Object.assign(initialValues, {
      location: {
        search: address,
        selectedPlace: { address, origin: geolocation },
      },
    });
  }

  useEffect(() => {
    if (isCartPage && selectedListingId?.length > 0) {
      const cartListings =
      combinedListings && combinedListings[selectIndex] && combinedListings[selectIndex].flat();
      const selectedListing =
      cartListings && cartListings.filter(st => selectedListingId.includes(st.id.uuid));

      const newCartData = (selectedListingId?.length > 0
        ? selectedListing && selectedListing
        : cartListings && cartListings.length && cartListings
      )?.map(item => {
        const currentStock = item && item.currentStock;
        const price = item && item.attributes && item.attributes.price;
        const title = item && item.attributes && item.attributes.title;
        const publicData = item && item.attributes && item.attributes.publicData;
        const { listingImagesUrl, packagePreDefinedWeight, boxSizes } = publicData || {};
        const images = item && item.images.map(e => e.id.uuid);
        const authorId = item && item.author && item.author.id && item.author.id.uuid;
        const quantity =
          currentStock && currentStock.attributes && currentStock.attributes.quantity;
        const currency = price && price.currency;

        return {
          listingId: item.id.uuid,
          stockCount: 1,
          oldStock: quantity,
          price: price?.amount / 100,
          title,
          authorId,
          currency,
          images,
          imagesUrl: listingImagesUrl,
          packageWeight: parseInt(packagePreDefinedWeight) || parseInt(boxSizes?.weight),
        };
      });

      setCartData(newCartData);
    }
  }, [isCartPage, selectedListingId, combinedListings, ind]);


  const handleSubmit = (values, process, props, stripe, submitting, setSubmitting) => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const listing = currentListing || {};
    const { phoneNumber, location } =
      (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};


    const { apartment, city, postalCode, state, address, poBoxNumber } = location || {};
    const { card, paymentMethod: selectedPaymentMethod, formValues } = values;
    const { saveAfterOnetimePayment: saveAfterOnetimePaymentRaw } = formValues;

    const saveAfterOnetimePayment =
      Array.isArray(saveAfterOnetimePaymentRaw) && saveAfterOnetimePaymentRaw.length > 0;
    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);
    const hasDefaultPaymentMethodSaved = hasDefaultPaymentMethod(stripeCustomerFetched, currentUser);
    const stripePaymentMethodId = hasDefaultPaymentMethodSaved
      ? currentUser?.stripeCustomer?.defaultPaymentMethod?.attributes?.stripePaymentMethodId
      : null;

    const shippingDetails = {
      buyerShippingAddress: {
        apartment,
        city,
        postalCode,
        state,
        phoneNumber,
        country: 'US',
        address,
        email: !!currentUser?.id && getUserDetails(currentUser).email,
        poBoxNumber,
      },
    };
    const { stripeCustomerId } = currentUser?.stripeCustomer?.attributes || {};
    const extraOrderParams = {
      orderNumber, selectedRates, cartData, defaultRates, listing: currentListing, stripeCustomerId, currentUser, stripePaymentMethodId
    }

    const orderParams = getOrderParams(shippingDetails, config, extraOrderParams, values);

    // Initiate offer
    onInitiateOffer(orderParams)
      .then(({ order }) => {
        onConfirmTransactionExtraAttributes({ orderNumber, transactionId: order.id, cartItems: cartData, listing: currentListing, isOffer: true });
        setSubmitting(false);

        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routeConfiguration, {
          id: order.id.uuid,
        });
        const initialValues = {};

        setOrderPageInitialValues(initialValues, routeConfiguration, dispatch);
        onSubmitCallback();
        history.push(orderDetailsPath);
      })
      .catch(error => {
        console.error(error);
        setSubmitting(false);
      });
  };


  const onStripeInitialized = (stripe, process) => {
    const tx = null;

    // We need to get up to date PI, if payment is pending but it's not expired.
    const shouldFetchPaymentIntent =
      stripe &&
      !paymentIntent &&
      tx?.id &&
      process?.getState(tx) === process?.states.PENDING_PAYMENT &&
      !hasPaymentExpired(tx, process);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData?.stripePaymentIntents?.default || {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  };
  const showPaymentForm = !!(
    currentUser
  );
  const transactionProcessAlias = currentListing?.attributes?.publicData?.transactionProcessAlias;
  const process = DOTS_PROCESS_NAME ? getProcess(DOTS_PROCESS_NAME) : null;
  const hasPaymentIntentUserActionsDone =
    paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

  const makeAnOfferModal = isMakeAnOfferModal ? (
    <Modal
      id="makeAnOfferModal"
      containerClassName={css.test}
      isOpen={isMakeAnOfferModal}
      onClose={() => {
        handleOfferModal();
        setIsPriceModalOpen(false)
      }}
      usePortal
      onManageDisableScrolling={() => { }}
      closeButtonMessage={'close'}
      className={css.offerForm}
    >
      {!isPriceModalOpen && <OfferForm
        onManageDisableScrolling={() => { }}
        onSubmit={() => onSubmitInquiry}
        currentUser={currentUser}
        initialValues={initialValues}
        listing={currentListing}
        paymentMethodId={paymentMethodId}
        isUpdateAddress={isUpdateAddress}
        setIsUpdateAddress={setIsUpdateAddress}
        editAddress={editAddress}
        setEditAddress={setEditAddress}
      />}
      <div className={css.paymentInfo}>
        {/* {errorMessages.paymentExpiredMessage} */}

        {showPaymentForm ? (
          <StripeOfferForm
            className={css.paymentForm}
            onSubmit={values =>
              handleSubmit(values, process, props, stripe, submitting, setSubmitting)
            }
            inProgress={submitting}
            formId="CheckoutPagePaymentForm"
            authorDisplayName={currentListing?.author?.attributes?.profile?.displayName}
            initialValues={{}}
            loadingData={!stripeCustomerFetched}
            currentUser={currentUser}
            savePaymentMethod={savePaymentMethod}
            isPaymentMethodChange={isPaymentMethodChange}
            handlePaymentMethod={() => {
              if (isPaymentMethodChange) {
                setIsPaymentMethodChange(false);
              } else {
                setIsPaymentMethodChange(true);
              }
            }}
            handleSavedMethods={() => {
              if (savePaymentMethod) {
                setSavePaymentMethod(false);
              } else {
                setSavePaymentMethod(true);
              }
            }}
            defaultPaymentMethod={
              hasDefaultPaymentMethod(stripeCustomerFetched, currentUser)
                ? currentUser.stripeCustomer.defaultPaymentMethod
                : null
            }
            onStripeInitialized={stripe => {
              setStripe(stripe);
              return onStripeInitialized(stripe, process, props);
            }}
            listingLocation={currentListing?.attributes?.publicData?.location}
            locale={config.localization.locale}
            stripePublishableKey={config.stripe.publishableKey}
            marketplaceName={config.marketplaceName}
            isBooking={isBookingProcessAlias(transactionProcessAlias)}
            isFuzzyLocation={config.maps.fuzzy.enabled}
            setIsPriceModalOpen={setIsPriceModalOpen}
            handleOfferModal={handleOfferModal}
            currentListing={currentListing}
            isPriceModalOpen={isPriceModalOpen}
            selectedRates={selectedRates}
            cartData={cartData}
            setSelectedRates={setSelectedRates}
            setEditAddress={setEditAddress}
            offerModal={true}
            defaultRates={defaultRates}
            hasHandledCardPayment={hasPaymentIntentUserActionsDone}
            {...rest}
          />
        ) : null}
      </div>
      {/* </>
      )} */}
    </Modal>
  ) : null;


  return (
    <div>
      {makeAnOfferModal}
    </div>
  );
}
