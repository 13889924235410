/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import NoShippingImg from '../../../../assets/noDel.png';
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser, ensurePaymentMethodCard } from '../../../../util/data';
import USPS from '../../../../assets/USPS.png';
import {
  Heading,
  Form,
  FieldCheckbox,
  IconSpinner,
  SavedCardDetails,
  Button,
  FieldTextInput,
} from '../../../../components';
import css from './StripeOfferForm.module.css';


const getClientSecret = setupIntent => {
  return setupIntent && setupIntent.attributes ? setupIntent.attributes.clientSecret : null;
};

const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;

  if (!code || !type) {
    // Not a proper Stripe error object
    return intl.formatMessage({ id: 'StripePaymentForm.genericError' });
  }

  const translationId =
    type === 'validation_error'
      ? `StripePaymentForm.stripe.validation_error.${code}`
      : `StripePaymentForm.stripe.${type}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

const stripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Inter',
    },
  ],
};

// card (being a Stripe Elements component), can have own styling passed to it.
// However, its internal width-calculation seems to break if font-size is too big
// compared to component's own width.
const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;
const cardStyles = {
  base: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Inter", Helvetica, Arial, sans-serif',
    fontSize: isMobile ? '14px' : '16px',
    fontSmoothing: 'antialiased',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
    color: '#4A4A4A',
    '::placeholder': {
      color: '#B2B2B2',
    },
  },
};

const OneTimePaymentWithCardElement = props => {
  const {
    cardClasses,
    formId,
    handleStripeElementRef,
    hasCardError,
    error,
    label,
    intl,
    marketplaceName,
  } = props;

  const labelText =
    label || intl.formatMessage({ id: 'StripePaymentForm.saveAfterOnetimePayment' });
  return (
    <React.Fragment>
      <label className={css.paymentLabel} htmlFor={`${formId}-card`}>
        <FormattedMessage id="StripePaymentForm.paymentCardDetails" />
      </label>
      <div className={cardClasses} id={`${formId}-card`} ref={handleStripeElementRef} />
      {hasCardError ? <span className={css.error}>{error}</span> : null}
      <div className={css.saveForLaterUse}>
        {!label ? <FieldCheckbox
          className={css.saveForLaterUseCheckbox}
          textClassName={css.saveForLaterUseLabel}
          id="saveAfterOnetimePayment"
          name="saveAfterOnetimePayment"
          label={labelText}
          value="saveAfterOnetimePayment"
          useSuccessColor
        /> : <div className={css.saveForLaterUseLabel}>
          {labelText}
        </div>}

        <span className={classNames(css.saveForLaterUseLegalInfo,label && css.saveForLaterUseLabelWithNoPadding)}>
          <FormattedMessage
            id="StripePaymentForm.saveforLaterUseLegalInfo"
            values={{ marketplaceName }}
          />
        </span>
      </div>
    </React.Fragment>
  );
};

const PaymentMethodSelector = props => {
  const {
    cardClasses,
    formId,
    changePaymentMethod,
    defaultPaymentMethod,
    handleStripeElementRef,
    hasCardError,
    error,
    paymentMethod,
    intl,
    marketplaceName,
  } = props;
  const last4Digits = defaultPaymentMethod.attributes.card.last4Digits;
  const labelText = intl.formatMessage(
    { id: 'StripePaymentForm.replaceAfterOnetimePayment' },
    { last4Digits }
  );

  return (
    <React.Fragment>
      <div className={css.dflex}>
        <Heading as="h3" rootClassName={css.heading}>
          <FormattedMessage id="SavedCardDetails.paymentText" />
        </Heading>
        <SavedCardDetails
          className={css.paymentMethodSelector}
          card={defaultPaymentMethod.attributes.card}
          onChange={changePaymentMethod}
        />
      </div>
      {paymentMethod === 'replaceCard' ? (
        <OneTimePaymentWithCardElement
          cardClasses={cardClasses}
          formId={formId}
          handleStripeElementRef={handleStripeElementRef}
          hasCardError={hasCardError}
          error={error}
          label={labelText}
          intl={intl}
          marketplaceName={marketplaceName}
        />
      ) : null}
    </React.Fragment>
  );
};

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
      ? 'onetimeCardPayment'
      : selectedPaymentMethod;
};

// Should we show onetime payment fields and does StripeElements card need attention
const checkOnetimePaymentFields = (
  cardValueValid,
  selectedPaymentMethod,
  hasDefaultPaymentMethod,
  hasHandledCardPayment
) => {
  const useDefaultPaymentMethod =
    selectedPaymentMethod === 'defaultCard' && hasDefaultPaymentMethod;
  // Billing details are known if we have already handled card payment or existing default payment method is used.
  const billingDetailsKnown = hasHandledCardPayment || useDefaultPaymentMethod;

  // If onetime payment is used, check that the StripeElements card has valid value.
  const oneTimePaymentMethods = ['onetimeCardPayment', 'replaceCard'];
  const useOnetimePaymentMethod = oneTimePaymentMethods.includes(selectedPaymentMethod);
  const onetimePaymentNeedsAttention =
    !billingDetailsKnown && !(useOnetimePaymentMethod && cardValueValid);

  return {
    onetimePaymentNeedsAttention,
    showOnetimePaymentFields: useOnetimePaymentMethod,
  };
};

const initialState = {
  error: null,
  cardValueValid: false,
  // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
  // Check SavedCardDetails component for more information
  paymentMethod: null,
  isChecked: false,
};

/**
 * Payment form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle payment. `stripe.confirmCardPayment`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class StripeOfferForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.updateBillingDetailsToMatchShippingAddress = this.updateBillingDetailsToMatchShippingAddress.bind(
      this
    );
    this.handleCardValueChange = this.handleCardValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.initializeStripeElement = this.initializeStripeElement.bind(this);
    this.handleStripeElementRef = this.handleStripeElementRef.bind(this);
    this.changePaymentMethod = this.changePaymentMethod.bind(this);
    this.finalFormAPI = null;
    this.cardContainer = null;
  }

  componentDidMount() {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripeOfferForm');
    }

    const publishableKey = this.props.stripePublishableKey;
    if (publishableKey) {
      const {
        onStripeInitialized,
        hasHandledCardPayment,
        defaultPaymentMethod,
        loadingData,
      } = this.props;
      this.stripe = window.Stripe(publishableKey);
      onStripeInitialized(this.stripe);
      if (!(hasHandledCardPayment || defaultPaymentMethod || loadingData)) {
        this.initializeStripeElement();
      }
    }
  }

  componentWillUnmount() {
    if (this.card) {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
  }

  initializeStripeElement(element) {
    const elements = this.stripe.elements(stripeElementsOptions);

    if (!this.card) {
      this.card = elements.create('card', { style: cardStyles });
      this.card.mount(element || this.cardContainer);
      this.card.addEventListener('change', this.handleCardValueChange);
      // EventListener is the only way to simulate breakpoints with Stripe.
      window.addEventListener('resize', () => {
        if (this.card) {
          if (window.innerWidth < 768) {
            this.card.update({ style: { base: { fontSize: '14px', lineHeight: '24px' } } });
          } else {
            this.card.update({ style: { base: { fontSize: '18px', lineHeight: '24px' } } });
          }
        }
      });
    }
  }

  updateBillingDetailsToMatchShippingAddress(shouldFill) {
    const formApi = this.finalFormAPI;
    const values = formApi.getState()?.values || {};
    formApi.batch(() => {
      formApi.change('name', shouldFill ? values.recipientName : '');
      formApi.change('billingLocation', shouldFill ? values.location : {});
      formApi.change('addressLine2', shouldFill ? values.recipientAddressLine2 : '');
      formApi.change('postal', shouldFill ? values.recipientPostal : '');
      formApi.change('city', shouldFill ? values.recipientCity : '');
      formApi.change('state', shouldFill ? values.recipientState : '');
      formApi.change('country', shouldFill ? values.recipientCountry : '');
      formApi.change('sameAddress', shouldFill ? true : false);
    });
  }

  changePaymentMethod(changedTo) {
    if (this.card && changedTo === 'defaultCard') {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
      this.setState({ cardValueValid: false });
    }
    this.setState({ paymentMethod: changedTo });
    if (changedTo === 'defaultCard' && this.finalFormAPI) {
      this.finalFormAPI.change('sameAddressCheckbox', undefined);
    } else if (changedTo === 'replaceCard' && this.finalFormAPI) {
      this.finalFormAPI.change('sameAddressCheckbox', ['sameAddress']);
      this.updateBillingDetailsToMatchShippingAddress(true);
    }
  }

  handleStripeElementRef(el) {
    this.cardContainer = el;
    if (this.stripe && el) {
      this.initializeStripeElement(el);
    }
  }

  handleCardValueChange(event) {
    const { intl } = this.props;
    const { error, complete } = event;

    const postalCode = event.value.postalCode;
    if (this.finalFormAPI) {
      this.finalFormAPI.change('postal', postalCode);
    }

    this.setState(prevState => {
      return {
        error: error ? stripeErrorTranslation(intl, error) : null,
        cardValueValid: complete,
      };
    });
  }
  handleSubmit(values) {
    const {
      onSubmit,
      inProgress,
      formId,
      hasHandledCardPayment,
      defaultPaymentMethod,
    } = this.props;
    const { initialMessage } = values;
    const { cardValueValid, paymentMethod } = this.state;
    const hasDefaultPaymentMethod = defaultPaymentMethod?.id;
    const selectedPaymentMethod = getPaymentMethod(paymentMethod, hasDefaultPaymentMethod);
    const { onetimePaymentNeedsAttention } = checkOnetimePaymentFields(
      cardValueValid,
      selectedPaymentMethod,
      hasDefaultPaymentMethod,
      hasHandledCardPayment
    );

    if (inProgress || onetimePaymentNeedsAttention) {
      // Already submitting or card value incomplete/invalid
      return;
    }

    const params = {
      message: initialMessage ? initialMessage.trim() : null,
      card: this.card,
      formId,
      formValues: values,
      paymentMethod: getPaymentMethod(
        paymentMethod,
        ensurePaymentMethodCard(defaultPaymentMethod).id
      ),
    };
    onSubmit(params);
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      loadingData,
      formId,
      paymentInfo,
      authorDisplayName,
      showInitialMessageInput,
      intl,
      initiateOrderError,
      confirmCardPaymentError,
      confirmPaymentError,
      invalid,
      handleSubmit,
      form: formApi,
      hasHandledCardPayment,
      defaultPaymentMethod,
      listingLocation,
      askShippingDetails,
      showPickUplocation,
      totalPrice,
      locale,
      stripePublishableKey,
      marketplaceName,
      isBooking,
      isFuzzyLocation,
      values,
      currentUser,
      setIsPriceModalOpen,
      handleOfferModal,
      onFetchAuthorDetails,
      isPriceModalOpen,
      fecthRatesSuccess,
      selectedRates,
      defaultRates,
      currentListing,
      fecthRatesInProgress,
      cartData,
      setSelectedRates,
      setEditAddress,
      editAddress,
      fetchStripeCustomer,
      onHandleCardSetup,
      onCreateSetupIntent,
      onSavePaymentMethod,
      validateAddress,
      ...rest
    } = formRenderProps;

    this.finalFormAPI = formApi;

    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(defaultPaymentMethod);
    const billingDetailsNeeded = !(hasHandledCardPayment || confirmPaymentError);
    const { phoneNumber, location, bagItems = [] } =
      (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};
    const { cardValueValid, paymentMethod } = this.state;
    const hasDefaultPaymentMethod = ensuredDefaultPaymentMethod.id;
    const selectedPaymentMethod = getPaymentMethod(paymentMethod, hasDefaultPaymentMethod);
    const { onetimePaymentNeedsAttention, showOnetimePaymentFields } = checkOnetimePaymentFields(
      cardValueValid,
      selectedPaymentMethod,
      hasDefaultPaymentMethod,
      hasHandledCardPayment
    );

    const submitDisabled = invalid || onetimePaymentNeedsAttention || submitInProgress;
    const hasCardError = this.state.error && !submitInProgress;
    const hasPaymentErrors = confirmCardPaymentError || confirmPaymentError;
    const classes = classNames(rootClassName || css.root, className);
    const cardClasses = classNames(css.card, {
      [css.cardSuccess]: this.state.cardValueValid,
      [css.cardError]: hasCardError,
    });

    const cartItemsPrice = cartData?.reduce((accumulator, obj) => accumulator + obj.price, 0);

    const totalPaid = parseFloat(values.offerPrice) + parseFloat(selectedRates?.amount ? selectedRates?.amount : defaultRates?.amount);
    const result = parseInt((totalPaid * 100).toFixed(2));

    const { apartment, city, postalCode, state, address } = location || {};
    const userName = currentUser?.attributes?.profile
      ? `${currentUser.attributes.profile.displayName}`
      : null;


    const handleSubmitCard = params => {
      const ensuredCurrentUser = ensureCurrentUser(currentUser);
      const stripeCustomer = ensuredCurrentUser.stripeCustomer;
      const addressMaybe =
        address && postalCode
          ? {
            address: {
              city: city,
              country: 'US',
              line1: address,
              line2: apartment,
              postal_code: postalCode,
              state: state,
            },
          }
          : {};

      const billingDetails = {
        name: userName,
        email: ensureCurrentUser(currentUser).attributes.email,
        ...addressMaybe,
      };

      const paymentParams = {
        payment_method_data: {
          billing_details: billingDetails,
        },
      };

      onCreateSetupIntent()
        .then(setupIntent => {
          const stripeParams = {
            stripe: this.stripe,
            card: this.card,
            setupIntentClientSecret: getClientSecret(setupIntent),
            paymentParams,
          };
          return onHandleCardSetup(stripeParams);
        })
        .then(result => {
          const newPaymentMethod = result.setupIntent.payment_method;
          // Note: stripe.handleCardSetup might return an error inside successful call (200), but those are rejected in thunk functions.

          return onSavePaymentMethod(stripeCustomer, newPaymentMethod);
        })
        .then(() => {
          // Update currentUser entity and its sub entities: stripeCustomer and defaultPaymentMethod
          fetchStripeCustomer();
          setIsPriceModalOpen(true)
        })
        .catch(error => {
          console.error(error);
        });
    };

    return (
      <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="OrderDetailsPage">
        {isPriceModalOpen ?
          <div className={css.modalMyOfferContent}>
            <div>
              <h2 className={css.modalTitle}>
                <FormattedMessage id="ListingPage.offerPriceHeading" />
              </h2>
            </div>
            <div className={css.offerPriceNote}>
              <span>
                <FormattedMessage id="ListingPage.offerPriceNote" />
              </span>
            </div>
            <div>
              <p className={css.listedPrice}>
                <FormattedMessage
                  id="ListingPage.listingPrice"
                  values={{
                    listingPrice: cartItemsPrice || currentListing?.attributes?.price?.amount / 100,
                  }}
                />
              </p>
              <FieldTextInput
                name="offerPrice"
                id="offerPrice"
                label={<FormattedMessage id="ListingPage.offerlabel" />}
              />
              <p>
                {' '}
                <FormattedMessage
                  id="ListingPage.validationText"
                  values={{
                    minPrice:
                      (cartItemsPrice * 50) / 100 ||
                      (currentListing?.attributes?.price?.amount * 50) / 100 / 100,
                  }}
                />
              </p>
              <div className={css.pageRoot}>
                {fecthRatesSuccess?.length > 0 ? (
                  <div>
                    <h5 className={css.deliveryMethodTitle}>
                      <FormattedMessage id="ShippingDetails.selectedHeading" />
                    </h5>
                    <span>
                      <FormattedMessage id="ShippingDetails.disclaimer" />
                    </span>
                    <br />
                    <div className={css.deliveryMethodCardWrapper}>
                      {fecthRatesSuccess?.map((data, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setSelectedRates(data);
                            }}
                            className={classNames(
                              css.deliveryMethodCard,
                              (selectedRates?.servicelevel ? selectedRates?.servicelevel?.name === data?.servicelevel?.name : defaultRates?.servicelevel?.name === data?.servicelevel?.name) &&
                              css.selectedCard
                            )}
                          >
                            <div className={css.methodImage}>
                              <img src={USPS} alt="USPS" />
                              <div>
                                <h4 className={css.servicelevelName}>{data.servicelevel.name}</h4>
                                <h5 className={css.price}>
                                  {' '}
                                  Price : <b>${data.amount}</b>
                                </h5>
                              </div>
                            </div>

                            <p className={css.duration_terms}>{data.duration_terms}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className={css.contentCenter}>
                    {fecthRatesInProgress ? (
                      '...Loading'
                    ) : (
                      <>
                        <img src={NoShippingImg} alt="img" />
                        <div>{!validateAddress ? <span><FormattedMessage id="ShippingDetails.addressError" /> </span> : <span> <FormattedMessage id="ShippingDetails.inFoError" /></span>}</div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className={css.bottomText}>
                <FormattedMessage id="ListingPage.totalPrice" />${' '}
                {totalPaid
                  ? totalPaid.toFixed(2)
                  : fecthRatesSuccess?.length > 0
                    ? parseFloat(selectedRates?.amount ? selectedRates?.amount : defaultRates?.amount)
                    : 0}
              </div>
              <Button
                inProgress={submitInProgress}
                type="submit"
                disabled={
                  !values.offerPrice ||
                  values.offerPrice < (cartItemsPrice * 50) / 100
                }

                className={css.submitOfferBtn}
              >
                <FormattedMessage id="ListingPage.offerSubmit" />
              </Button>
            </div>
          </div>
          :
          <div>
            {billingDetailsNeeded && !loadingData ? (
              <React.Fragment>
                {hasDefaultPaymentMethod ? (
                  <PaymentMethodSelector
                    cardClasses={cardClasses}
                    formId={formId}
                    defaultPaymentMethod={ensuredDefaultPaymentMethod}
                    changePaymentMethod={this.changePaymentMethod}
                    handleStripeElementRef={this.handleStripeElementRef}
                    hasCardError={hasCardError}
                    error={this.state.error}
                    paymentMethod={selectedPaymentMethod}
                    intl={intl}
                    marketplaceName={marketplaceName}
                  />
                ) : (
                  <React.Fragment>
                    <Heading as="h3" rootClassName={css.heading}>
                      <FormattedMessage id="SavedCardDetails.paymentText" />
                    </Heading>
                    {<OneTimePaymentWithCardElement
                      cardClasses={cardClasses}
                      formId={formId}
                      handleStripeElementRef={this.handleStripeElementRef}
                      hasCardError={hasCardError}
                      error={this.state.error}
                      intl={intl}
                      marketplaceName={marketplaceName}
                    />}
                  </React.Fragment>
                )}

              </React.Fragment>
            ) : loadingData ? (
              <p className={css.spinner}>
                <IconSpinner />
              </p>
            ) : null}

            {/* {location ? (
              <p className={css.editAddress} onClick={() => setEditAddress(true)}>
                <FormattedMessage id="ListingPage.makeAnOfferEditShippingDetails" />
              </p>
            ) : (
              <p onClick={() => setEditAddress(true)} className={css.editAddress}>
                <FormattedMessage id="ListingPage.addAddress" />
              </p>
            )} */}

            <Button type="button" disabled={!location || submitDisabled || (!values?.saveAfterOnetimePayment?.length && !defaultPaymentMethod)} inProgress={fecthRatesInProgress} onClick={() => {
              if ((!defaultPaymentMethod || paymentMethod === 'replaceCard')) {
                handleSubmitCard(values);
              } else {
                setIsPriceModalOpen(true);
              }
              onFetchAuthorDetails({ listing: currentListing, cartItems: cartData })

            }}
              className={css.confirmDetails}
            ><FormattedMessage id="ListingsPage.continue" /></Button>
          </div>

        }
      </Form>);
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return (
      <FinalForm
        onSubmit={this.handleSubmit}
        keepDirtyOnReinitialize={true}
        {...rest}
        render={this.paymentForm}
      />
    );
  }
}

StripeOfferForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  showInitialMessageInput: true,
  hasHandledCardPayment: false,
  defaultPaymentMethod: null,
  initiateOrderError: null,
  confirmCardPaymentError: null,
  confirmPaymentError: null,
  askShippingDetails: false,
  showPickUplocation: false,
  listingLocation: null,
  totalPrice: null,
  isFuzzyLocation: false,
};

StripeOfferForm.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  confirmCardPaymentError: object,
  confirmPaymentError: object,
  formId: string.isRequired,
  onSubmit: func.isRequired,
  authorDisplayName: string.isRequired,
  showInitialMessageInput: bool,
  hasHandledCardPayment: bool,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
  askShippingDetails: bool,
  showPickUplocation: bool,
  listingLocation: shape({
    address: string.isRequired,
    building: string,
  }),
  totalPrice: string,
  locale: string.isRequired,
  stripePublishableKey: string.isRequired,
  marketplaceName: string.isRequired,
  isBooking: bool.isRequired,
  isFuzzyLocation: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(StripeOfferForm);
