import React, { useEffect, useState } from 'react';
import css from './OfferForm.module.css';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../../util/validators';
import * as validators from '../../../util/validators';
import { propTypes } from '../../../util/types';
import classNames from 'classnames';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import {
  Button,
  Form,
  FieldTextInput,
  FieldLocationAutocompleteInput,
  FieldPhoneNumberInput,
} from '../../../components';
import { useDispatch } from 'react-redux';
import { addressValidationWithShippo, updateProfile } from '../../ProfileSettingsPage/ProfileSettingsPage.duck';
import usStates from 'us-state-codes';

const identity = v => v;

const OfferFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        uploadInProgress,
        invalid,
        pristine,
        intl,
        values,
        form,
        currentUser,
        paymentMethodId,
        isUpdateAddress,
        setIsUpdateAddress,
        setEditAddress,
        editAddress
      } = fieldRenderProps;

      const [addressError, setAddressError] = useState('');
      const [updateAddrInProgress, setUpdateAddrInProgress] = useState(false);

      const { phoneNumber } =
        (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};
      const userName = currentUser?.attributes?.profile
        ? `${currentUser.attributes.profile.displayName}`
        : null;
      const dispatch = useDispatch();

      const addressRequiredMessage = intl.formatMessage({
        id: 'ProfileSettingsForm.addressRequired',
      });

      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'ProfileSettingsForm.addressNotRecognized',
      });

      const phoneNumberValid = validators.phoneNumberFormatValid(
        intl.formatMessage({
          id: 'ProfileSettingsForm.phoneNumberValid',
        })
      );

      // phoneNumber
      const phoneNumberRequired = validators.required(
        intl.formatMessage({
          id: 'ProfileSettingsForm.phoneNumberRequired',
        })
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || pristine || uploadInProgress || submitInProgress;

      const { address, origin } = values?.location?.selectedPlace || {};

      const addShippingDetails = () => {
        setUpdateAddrInProgress(true)
        const profile = {
          protectedData: {
            phoneNumber: values?.phoneNumber,
            location:
              address && origin && origin.lat
                ? {
                  address,
                  origin: { lat: origin.lat, lng: origin.lng },
                  apartment: values?.apartment,
                  city: values?.city,
                  postalCode: values?.postalCode,
                  state: values?.state,
                  poBoxNumber: values?.poBoxNumber,
                }
                : null,
          },
        };

        dispatch(addressValidationWithShippo({
          postalCode: values?.postalCode,
          state: values?.state,
          city: values?.city,
          addressLine1: address?.split(',')[0],
          addressLine2: values?.apartment,
          email: currentUser?.attributes?.email,
          firstName: userName,
        })).then((validateAddress) => {
          if (validateAddress) {
            dispatch(updateProfile(profile));
            setIsUpdateAddress(false);
            setEditAddress(false);
            setUpdateAddrInProgress(false);
            setAddressError('')
          } else {
            setAddressError('Address not found')
            setUpdateAddrInProgress(false);
          }
        })
      };

      useEffect(() => {
        const parseAddress = address => {
          // Split and trim each part to avoid issues with extra spaces
          const parts = address.split(',').map(part => part.trim());
          return parts.slice(-3); // Get the last three values: City, State Zip, Country
        };

        const extractStateAndZip = stateZip => {
          // Assuming US ZIP codes are 5 digits and potentially followed by a hyphen and 4 digits
          const match = stateZip.match(/(.*?)(\d{5})(-\d{4})?$/);
          if (!match) return {}; // In case the regex does not match

          const [_, stateName, zip] = match;
          return { stateName: stateName.trim(), zip };
        };

        const addressArray = parseAddress(values?.location?.search || '');

        if (addressArray.length >= 3) {
          // Ensure we have City, State Zip, and Country
          const { stateName, zip } = extractStateAndZip(addressArray[1]);

          form.change('city', addressArray[0]);
          form.change('postalCode', zip);
          form.change('state', usStates.getStateCodeByStateName(stateName) || stateName);
        }
      }, [values?.location?.search, form]);

      return (
        <div>
          {editAddress || isUpdateAddress ? (
            <Form className={classes} onSubmit={handleSubmit}>
              <div>
                <h2 className={css.modalTitle}>
                  <FormattedMessage id="ListingPage.makeAnOfferHeading" />
                </h2>
              </div>
              <div className={css.makeOffer}>
                <p className={css.subTitle}>
                  <FormattedMessage id="ListingPage.makeAnOfferText" />
                </p>
              </div>
              <div className={css.rightContainer}>
                <FieldLocationAutocompleteInput
                  className={css.locationBox}
                  rootClassName={css.locationAddress}
                  inputClassName={css.locationAutocompleteInput}
                  iconClassName={css.locationAutocompleteInputIcon}
                  predictionsClassName={css.predictionsRoot}
                  validClassName={css.validLocation}
                  name="location"
                  label={intl.formatMessage({ id: 'ProfileSettingsForm.addressLabel' })}
                  placeholder={intl.formatMessage({
                    id: 'ProfileSettingsForm.addressPlaceholder',
                  })}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values?.location}
                  validate={composeValidators(
                    autocompleteSearchRequired(addressRequiredMessage),
                    autocompletePlaceSelected(addressNotRecognizedMessage)
                  )}
                />
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="apartment"
                    name="apartment"
                    label={intl.formatMessage({ id: 'ProfileSettingsForm.appartmentLabel' })}
                    placeholder={intl.formatMessage({
                      id: 'ProfileSettingsForm.appartmentPlaceholder',
                    })}
                  // validate={validators.required(
                  //   intl.formatMessage({ id: 'ProfileSettingsForm.appartmentValidate' })
                  // )}
                  />
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="city"
                    name="city"
                    label={intl.formatMessage({ id: 'ProfileSettingsForm.cityLabel' })}
                    placeholder={intl.formatMessage({
                      id: 'ProfileSettingsForm.cityPlaceholder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({ id: 'ProfileSettingsForm.cityValidate' })
                    )}
                  />
                </div>
                <div className={css.nameContainer}>
                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="state"
                    name="state"
                    label={intl.formatMessage({ id: 'ProfileSettingsForm.stateLabel' })}
                    placeholder={intl.formatMessage({
                      id: 'ProfileSettingsForm.statePlaceholder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({ id: 'ProfileSettingsForm.stateValidate' })
                    )}
                  />

                  <FieldTextInput
                    className={css.firstName}
                    type="text"
                    id="postalCode"
                    name="postalCode"
                    label={intl.formatMessage({ id: 'ProfileSettingsForm.postalCodeLabel' })}
                    placeholder={intl.formatMessage({
                      id: 'ProfileSettingsForm.postalCodePlaceholder',
                    })}
                    validate={validators.required(
                      intl.formatMessage({ id: 'ProfileSettingsForm.postalCodeValidate' })
                    )}
                  />
                </div>
                <div className={css.nameContainer}>
                  <FieldPhoneNumberInput
                    className={css.password}
                    type="text"
                    id={'phoneNumber'}
                    name="phoneNumber"
                    disabled
                    label={intl.formatMessage({
                      id: 'ProfileSettingsForm.phoneNumberLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'ProfileSettingsForm.phoneNumberPlaceholder',
                    })}
                    validate={validators.composeValidators(phoneNumberRequired, phoneNumberValid)}
                  />
                  <FieldTextInput
                    className={css.password}
                    id={`poBoxNumber`}
                    name="poBoxNumber"
                    type="text"
                    label={intl.formatMessage({ id: 'ShippingDetails.poBoxNumberLabel' })}
                  // validate={validators.required(
                  //   intl.formatMessage({ id: 'ProfileSettingsForm.poBoxNumberValidate' })
                  // )}
                  // disabled={true}
                  // value="US"
                  />
                </div>
              </div>
              <span className={css.errorText}>{addressError}</span>
              <div className={submitButtonWrapperClassName}>
                <Button
                  className={css.saveChangesBtn}
                  disabled={submitDisabled}
                  inProgress={updateAddrInProgress}
                  onClick={() => addShippingDetails()}
                >
                  <FormattedMessage id="ProfileSettingsForm.saveChanges" />
                </Button>
              </div>
            </Form>
          ) : (
            <div className={css.modalContent}>
              <div>
                <h2 className={css.modalTitle}>
                  <FormattedMessage id="ListingPage.makeAnOfferHeading" />
                </h2>
              </div>
              <div className={css.makeOffer}>
                <p className={css.subTitle}>
                  <FormattedMessage id="ListingPage.makeAnOfferText" />
                </p>
              </div>
              <div className={css.description}>
                {address ? (
                  <span>
                    <b>
                      {' '}
                      <FormattedMessage id="ListingPage.makeAnOfferShippingDetails" />
                    </b>
                    {userName}| {values?.poBoxNumber}|{address}
                  </span>
                ) : null}
                <br />
                {phoneNumber && (
                  <span className={css.phoneNumber}>
                    <b>
                      {' '}
                      <FormattedMessage id="ListingPage.makeAnOfferPhoneNumber" />
                    </b>{' '}
                    {phoneNumber}
                  </span>
                )}
                {!paymentMethodId && (
                  <>
                    <br />
                    {address ? (
                      <p className={css.editAddress} onClick={() => setEditAddress(true)}>
                        <FormattedMessage id="ListingPage.makeAnOfferEditShippingDetails" />
                      </p>
                    ) : (
                      <p onClick={() => setEditAddress(true)} className={css.editAddress}>
                        <FormattedMessage id="ListingPage.addAddress" />
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      );
    }}
  />
);

OfferFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendInquiryError: null,
};

OfferFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  inProgress: bool,
  sendInquiryError: propTypes.error,
  // from injectIntl
  intl: intlShape.isRequired,
};

const OfferForm = compose(injectIntl)(OfferFormComponent);

export default OfferForm;
